<template>
  <div id="service-form">
    <!-- DESCRIPTION -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h6>Descripció <small>(per ús intern)</small></h6>
      </b-col>
      <b-col cols="12">
        <b-form-input v-model="description" />
      </b-col>
    </b-row>

    <!-- SERIES -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h6>Sèrie</h6>
      </b-col>
      <b-col cols="12">
        <b-form-input v-model="series" />
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="save()"> Guarda </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton, BFormInput } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
  },
  props: {
    invoiceNumbering: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      description: null,
      series: null,
    };
  },
  created() {
    if (this.invoiceNumbering) this.initForm();
  },
  methods: {
    initForm() {
      this.description = this.invoiceNumbering.description;
      this.series = this.invoiceNumbering.series;
    },
    resetForm() {
      this.description = null;
      this.series = null;
    },
    cancel() {
      this.resetForm();
      this.$emit("cancel");
    },
    save() {
      if (this.invoiceNumbering) this.update();
      else this.create();
    },
    create() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("invoices/addInvoiceNumbering", {
          description: this.description,
          series: this.series,
        })
        .then(() => this.$emit("added"))
        .catch(() => this.$emit("add-error"))
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    update() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("invoices/updateInvoiceNumbering", {
          uuid: this.invoiceNumbering.uuid,
          description: this.description,
          series: this.series,
        })
        .then(() => this.$emit("edited"))
        .catch(() => this.$emit("edit-error"))
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
