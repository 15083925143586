<template>
  <b-card>
    <!-- INVOICE NUMBERINGS TABLE -->
    <b-static-table
      :items="mappedInvoiceNumberings"
      :fields="fields"
      :loading="loading"
      :button-text="$t('Nova sèrie')"
      show-search
      @button-click="onNewClicked"
      @item-edit-click="onEditClicked"
      @item-delete-click="onDeleteClicked"
    />

    <!-- ADD INVOICE NUMBERING -->
    <b-modal
      :id="'add-invoice-numbering-modal'"
      title="Afegeix una nova sèrie de facturació"
      scrollable
      hide-footer
    >
      <invoice-numbering-form
        @cancel="onAddInvoiceNumberingCancel"
        @added="onInvoiceNumberingAdded"
        @add-error="onAddInvoiceNumberingError"
      />
    </b-modal>

    <!-- EDIT INVOICE NUMBERING -->
    <b-modal
      :id="'edit-invoice-numbering-modal'"
      title="Edita la sèrie"
      scrollable
      hide-footer
    >
      <invoice-numbering-form
        :invoice-numbering="selectedInvoiceNumbering"
        @cancel="onEditInvoiceNumberingCancel"
        @edited="onInvoiceNumberingEdited"
        @edit-error="onEditInvoiceNumberingError"
      />
    </b-modal>
  </b-card>
</template>

<script>
import InvoiceNumberingForm from "@/views/accounting/configuration/invoice-numberings/components/InvoiceNumberingForm.vue";
import BStaticTable from "@foravila-core/components/b-static-table/BStaticTable.vue";
import { notifyError, notifySuccess } from "@/utils/methods";
import { BCard, BModal } from "bootstrap-vue";
import { formatDateStringToDate } from "@/utils/formatters";

export default {
  components: {
    BCard,
    BModal,
    InvoiceNumberingForm,
    BStaticTable,
  },
  data() {
    return {
      selectedInvoiceNumbering: null,
      fields: [
        { key: "description", label: this.$t("Descripció"), sortable: true },
        { key: "series", label: this.$t("Sèrie"), sortable: true },
        { key: "lastNumber", label: this.$t("Número"), sortable: true },
        { key: "lastDate", label: this.$t("Data"), sortable: true },
      ],
    };
  },
  computed: {
    loading() {
      return this.$store.getters["invoices/loading"];
    },
    invoiceNumberings() {
      return this.$store.getters["invoices/invoiceNumberings"];
    },
    mappedInvoiceNumberings() {
      if (!this.invoiceNumberings?.length) return [];
      return this.invoiceNumberings.map((invoiceNumbering) => ({
        uuid: invoiceNumbering.uuid || this.$t("No definit"),
        description: invoiceNumbering.description || this.$t("No definit"),
        series: invoiceNumbering.series || this.$t("No definit"),
        lastNumber:
          invoiceNumbering.lastNumber != null
            ? invoiceNumbering.lastNumber
            : this.$t("No definit"),
        lastDate:
          formatDateStringToDate(invoiceNumbering.lastDate) ||
          this.$t("No definit"),
      }));
    },
  },
  created() {
    // Only fetch invoiceNumberings if they are not fetched or being fetched
    if (this.invoiceNumberings.length === 0 && !this.loading)
      this.fetchInvoiceNumberings();
  },
  methods: {
    fetchInvoiceNumberings() {
      this.$store
        .dispatch("invoices/fetchInvoiceNumberings", {
          pagination: false,
          sort: "asc",
        })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchInvoiceNumberings.title"),
            this.$t("errors.fetchInvoiceNumberings.description")
          )
        );
    },
    onNewClicked() {
      this.$bvModal.show("add-invoice-numbering-modal");
    },
    onEditClicked(invoiceNumberingUuid) {
      this.selectedInvoiceNumbering = this.invoiceNumberings.find(
        (s) => s.uuid === invoiceNumberingUuid
      );
      this.$bvModal.show("edit-invoice-numbering-modal");
    },
    onDeleteClicked(invoiceNumberingUuid) {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("invoices/deleteInvoiceNumbering", invoiceNumberingUuid)
        .then(() => {
          // this.fetchInvoiceNumberings();
          notifySuccess(
            "Sèrie eliminada",
            "La sèrie ha estat eliminada correctament"
          );
        })
        .catch(() => {
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar eliminar la sèrie"
          );
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    onAddInvoiceNumberingCancel() {
      this.$bvModal.hide("add-invoice-numbering-modal");
    },
    onInvoiceNumberingAdded() {
      // this.fetchInvoiceNumberings()
      this.$bvModal.hide("add-invoice-numbering-modal");
      notifySuccess("Sèrie afegida", "La sèrie ha estat afegida correctament");
    },
    onAddInvoiceNumberingError() {
      notifyError("Error", "Hi ha hagut un error al intentar afegir la sèrie");
    },
    onEditInvoiceNumberingCancel() {
      this.$bvModal.hide("edit-invoice-numbering-modal");
    },
    onInvoiceNumberingEdited() {
      this.fetchInvoiceNumberings();
      this.$bvModal.hide("edit-invoice-numbering-modal");
      notifySuccess("Sèrie editada", "La sèrie ha estat editada correctament");
    },
    onEditInvoiceNumberingError() {
      notifyError("Error", "Hi ha hagut un error al intentar editar la sèrie");
    },
  },
};
</script>
